import {
  createContext,
  startTransition,
  useEffect,
  useRef,
  useState,
} from 'react';

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsContext = createContext<{
  client: Record<any, any> | null;
}>({client: null});

export function AnalyticsProvider(props: AnalyticsProviderProps) {
  const [client, setClient] = useState(null);
  const isClientLoading = useRef(false);

  useEffect(() => {
    startTransition(() => {
      (async () => {
        if (client || isClientLoading.current) {
          return;
        }
        isClientLoading.current = true;
        const mod = await import('rudder-sdk-js');

        mod.load(
          window.ENV.PUBLIC_RUDDERSTACK_WRITE_KEY,
          window.ENV.PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
        );
        mod.ready(() => {
          isClientLoading.current = false;
          mod.track('session_start');

          document?.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
              mod.track('session_end');
            }
          });
        });

        setClient(mod);
      })();
    });
  }, [client]);

  return (
    <AnalyticsContext.Provider
      value={{
        client,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
}
