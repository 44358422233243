import {useCallback, useContext} from 'react';

import {AnalyticsContext} from '../AnalyticsProvider';
import {AnalyticsEvent} from './analyticsEvent';

export const useRudderStack = () => {
  const {client} = useContext(AnalyticsContext);

  const trackEvent = useCallback(
    (event: AnalyticsEvent, data?: any) => {
      if (client && client.track && typeof client.track === 'function') {
        // @ts-ignore
        client.track(event, data);
      }
    },
    [client],
  );

  return {
    stack: client,
    trackEvent,
  };
};
